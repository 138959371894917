<script>
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { getNoResultsLabel } from '@/utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { contentCells, facets } from './config/contentCells';

export default {
  name: 'CompanyPaymentsView',
  components: {
    MuiAlgoliaList,
  },
  methods: { getNoResultsLabel },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    paymentsFilter() {
      return `invoice_company_fk:${this.company.id}`;
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells();
    this.facets = facets({
      operatorTimezone: this.operatorTimezone,
    });
  },
};
</script>
<template>
  <div
    class="CompanyPaymentsView"
    data-test-id="company_payments-view"
  >
    <h1 class="mb-2">
      Payments
    </h1>
    <MuiAlgoliaList
      :index="ALGOLIA_INDEXES.paymentTransactions"
      :table-config="contentCells"
      :filters="paymentsFilter"
      :facets="facets"
      empty-message="No payments to show"
      data-test-id="list"
    />
  </div>
</template>
