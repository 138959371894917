import {
  DATE_FORMAT,
  TIME_ZONE,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { COLORS } from '@emobg/vue-base';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const badgeColor = {
  success: COLORS.success,
  pending: COLORS.warning,
  failed: COLORS.danger,
};

export function contentCells() {
  return [
    {
      title: 'ID',
      attributeName: 'id',
      displayPriority: 1,
      minWidth: 90,
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      minWidth: 100,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: badgeColor[status],
      }),
    },
    {
      title: 'Date',
      attributeName: 'date',
      transformResult: result => reformatDateTime(result.date.date, DATE_FORMAT.defaultExtended, result.date.timezone),
      displayPriority: 1,
      minWidth: 150,
    },
    {
      title: 'Reference',
      attributeName: 'reference',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      title: 'Amount',
      attributeName: 'amount_formatted',
      displayPriority: 1,
      minWidth: 80,
    },
    {
      title: 'Invoice',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        target: '_blank',
        href: `/invoicing/invoices/${result.invoice_fk}/summary/details`,
        linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        text: result.invoice,
      }),
    },
    {
      title: 'User',
      minWidth: 180,
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.invoicing.payments,
          params: {
            userUuid: result.user_uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.user,
      }),
    },
    {
      title: 'Action',
      attributeName: 'action',
      displayPriority: 2,
      transformValue: sentenceCase,
      minWidth: 120,
    },
    {
      title: 'Payment method',
      attributeName: 'payment_method',
      displayPriority: 2,
      minWidth: 120,
    },
    {
      title: 'Payment provider',
      attributeName: 'payment_provider',
      displayPriority: 2,
      minWidth: 120,
    },
    {
      title: 'Provider status',
      attributeName: 'provider_status',
      displayPriority: 2,
      transformValue: sentenceCase,
      minWidth: 120,
    },
    {
      title: 'Provider reference',
      attributeName: 'provider_reference',
      displayPriority: 2,
      minWidth: 120,
    },
  ];
}

export const facets = ({ operatorTimezone = TIME_ZONE.default }) => [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Action',
      attributeName: 'action',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Payment provider',
      attributeName: 'payment_provider',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'rangeSlider',
    props: {
      title: 'Amount',
      attributeName: 'amount',
      from: value => value,
    },
  },
  {
    type: 'rangedatetimepicker',
    props: {
      title: 'Date',
      attributeName: 'date_ts',
      labels: {
        from: 'From',
        to: 'To',
        removeButton: 'Clear dates',
      },
      timezone: operatorTimezone,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Payment method',
      attributeName: 'payment_method',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Provider status',
      attributeName: 'provider_status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Currency',
      attributeName: 'currency',
    },
  },
];
