<script>
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { GenericModalComponent, StoreNotificationComponent } from '@/components';
import { Cancel } from '@/constants/modalButtons.const';
import { COMPANY_PAYMENT_METHODS_OPTIONS } from '../const/companyPaymentMethods.const';
import { COMPANY_SCOPES } from '../../../../store/CompanyModule';

export default {
  name: 'CompanyPaymentMethodForm',

  components: {
    GenericModalComponent,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },

  directives: {
    Validate,
  },

  props: {
    companyId: {
      type: Number,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isFormValid: false,
      inputs: {
        default: 1,
        type: COMPANY_PAYMENT_METHODS_OPTIONS.bankTransfer.value,
        number: '',
        holder: '',
      },
      paymentMethodOptions: [],
      personalProfileId: null,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      paymentMethodFormStatus: state => state.paymentMethodForm.STATUS,
    }),

    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            disabled: !this.isFormValid,
            loading: this.paymentMethodFormStatus.LOADING,
          },
          text: 'Add',
          listeners: {
            click: () => this.addPaymentMethod(),
          },
        },
      ];
    },

    isSepaPaymentMethodSelected() {
      return this.inputs.type === COMPANY_PAYMENT_METHODS_OPTIONS.sepa.value;
    },
  },

  async created() {
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_PAYMENT_METHODS_OPTIONS = COMPANY_PAYMENT_METHODS_OPTIONS;

    this.paymentMethodOptions = [
      COMPANY_PAYMENT_METHODS_OPTIONS.bankTransfer,
      COMPANY_PAYMENT_METHODS_OPTIONS.sepa,
    ];
  },

  methods: {
    get,
    map,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'postCompanyPaymentMethod',
    ]),

    async addPaymentMethod() {
      const payload = pick(this.inputs, [
        'default',
        'type',
        ...(this.isSepaPaymentMethodSelected ? ['holder', 'number'] : []),
      ]);

      await this.postCompanyPaymentMethod({
        companyId: this.companyId,
        payload,
      });

      if (!this.paymentMethodFormStatus.ERROR) {
        this.$emit('closeModal');
        this.onSuccess();
        this.$notify({ message: 'Payment method added' });
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    :size="SIZES.small"
    data-test-id="payment_method-form"
    class="CompanyPaymentMethodForm"
    title="Add payment method"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.paymentMethodForm"
        element="payment method"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
        <p class="emobg-body-1 mb-4">
          The new payment method added to the company will be the active payment method.
        </p>
        <div>
          <MuiSelect
            v-model="inputs.type"
            v-validate="{
              isRequired: true,
            }"
            :options="paymentMethodOptions"
            label="Payment method*"
            class="w-100"
            name="type"
          />
        </div>
        <template v-if="inputs.type === get(COMPANY_PAYMENT_METHODS_OPTIONS, 'sepa.value')">
          <div class="my-4">
            <MuiInputText
              v-model="inputs.holder"
              v-validate="{
                isRequired: true,
              }"
              label="Holder*"
              placeholder="Enter the holder name"
              name="holder"
            />
          </div>
          <div>
            <MuiInputText
              v-model="inputs.number"
              v-validate="{
                isRequired: true,
              }"
              label="IBAN*"
              placeholder="Enter the IBAN"
              name="iban"
            />
          </div>
        </template>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
