<script>
import { mapActions, mapState } from 'vuex';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel as CancelButtonAttrs } from '@/constants/modalButtons.const';
import {
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import { COMPANY_PAYMENT_METHOD_STATUS_ACTIONS } from '../const/companyPaymentMethods.const';

export default {
  name: 'CompanyPaymentMethodStatusModal',
  components: {
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    companyPaymentMethod: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      paymentMethodStatusStatus: state => state[COMPANY_SCOPES.paymentMethodStatus].STATUS,
    }),
    isActivateAction() {
      return this.action === COMPANY_PAYMENT_METHOD_STATUS_ACTIONS.activate;
    },
    modalButtons() {
      return [
        {
          ...CancelButtonAttrs,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            loading: this.paymentMethodStatusStatus.LOADING,
          },
          text: this.isActivateAction ? 'Activate' : 'Deactivate',
          listeners: {
            click: () => this.requestPaymentMethodStatus(),
          },
        },
      ];
    },
    modalContent() {
      const activatePaymentMethod = {
        title: 'Activate this payment method?',
        text: 'This action will activate the payment method.<br>You will be able to deactivate it again.',
      };
      const deactivatePaymentMethod = {
        title: 'Deactivate this payment method?',
        text: 'This action will deactivate the payment method.<br>You will be able to reactivate it again.',
      };
      return this.isActivateAction
        ? activatePaymentMethod
        : deactivatePaymentMethod;
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'activateCompanyPaymentMethod',
      'deactivateCompanyPaymentMethod',
    ]),
    async requestPaymentMethodStatus() {
      const {
        company_uuid: companyUuid,
        uuid: paymentMethodUuid,
      } = this.companyPaymentMethod;

      const request = this.isActivateAction
        ? this.activateCompanyPaymentMethod
        : this.deactivateCompanyPaymentMethod;

      await request({
        companyUuid,
        paymentMethodUuid,
      });

      if (!this.paymentMethodStatusStatus.ERROR) {
        this.$notify({
          message: `Payment method ${this.isActivateAction ? 'activated' : 'deactivated'}`,
          textAction: '',
        });
        this.onSuccess();
      }
      this.$emit('closeModal');
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="null"
    :buttons="modalButtons"
    :size="SIZES.small"
    class="CompanyPaymentMethodStatusModal"
    data-test-id="company_payment_method_status-modal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent v-bind="modalContent" />
    </template>
  </GenericModalComponent>
</template>
