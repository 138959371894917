var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyInvoicesView",
      attrs: { "data-test-id": "company_invoices-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Invoices ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          index: _vm.ALGOLIA_INDEXES.invoices,
          "table-config": _vm.contentCells,
          filters: _vm.filter,
          facets: _vm.facets,
          "empty-message": "No invoices to show",
          "data-test-id": "table",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }