var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyPaymentMethodsView",
      attrs: { "data-test-id": "company_payment_methods-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c("h1", [_vm._v(" Payment methods ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "add_payment_method-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isCompanyPaymentMethodsFormOpened = true
                },
              },
            },
            [_vm._v(" Add payment method ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "companyPaymentMethods",
            attrs: {
              facets: _vm.facets,
              filters: _vm.filters,
              "table-config": _vm.contentCells,
              "export-columns": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.companyPaymentMethods,
              "item-actions": _vm.itemActions,
              "empty-message": _vm.getNoResultsLabel("payment method"),
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isCompanyPaymentMethodsFormOpened
        ? _c("CompanyPaymentMethodForm", {
            key: "paymentMethodForm",
            attrs: {
              "company-id": _vm.get(_vm.company, "id"),
              "on-success": _vm.onSuccessForm,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isCompanyPaymentMethodStatusFormOpened
        ? _c(
            "CompanyPaymentMethodStatusModal",
            _vm._b(
              {
                attrs: { "on-success": _vm.onSuccessForm },
                on: { closeModal: _vm.closeModal },
              },
              "CompanyPaymentMethodStatusModal",
              _vm.formBinds,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }