var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CompanyPaymentMethodStatusModal",
        attrs: {
          header: null,
          buttons: _vm.modalButtons,
          size: _vm.SIZES.small,
          "data-test-id": "company_payment_method_status-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "DeleteModalBodyComponent",
                  _vm._b(
                    {},
                    "DeleteModalBodyComponent",
                    _vm.modalContent,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }