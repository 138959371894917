import { COLORS } from '@emobg/vue-base';
import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  formatDate,
  isFalsyString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { INVOICE_TYPES } from '@domains/Invoicing/const/INVOICE_TYPES';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const badgeColor = {
  open: COLORS.success,
  closed: COLORS.danger,
};

export const contentCells = [
  {
    title: 'Name',
    displayPriority: 1,
    minWidth: 120,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      target: '_blank',
      href: `/invoicing/invoices/${result.uuid}/summary/details`,
      linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
      text: `${result.serie}-${result.number}`,
    }),
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 1,
    minWidth: 120,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: badgeColor[status],
    }),
  },
  {
    title: 'Date',
    type: RECORD_TYPES.template,
    template: result => result.serie_type === INVOICE_TYPES.migration
      ? `Before ${formatDate(result.date_ts, DATETIME_FORMATS.LongMonthYear)}`
      : reformatDateTime(result.date.date, DATE_FORMAT.dob, result.date.timezone),
    displayPriority: 1,
    minWidth: 180,
    columnRatio: 1,
  },
  {
    title: 'Customer',
    attributeName: 'customer_name',
    displayPriority: 1,
    type: RECORD_TYPES.link,
    columnRatio: 1,
    minWidth: 150,
    url: result => companyProfileUrl(result.company_uuid),
  },
  {
    title: 'Sent',
    attributeName: 'sent',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 80,
    transformValue: value => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Amount',
    attributeName: 'gross_amount_formatted',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 100,
  },
  {
    title: 'Pending amount',
    attributeName: 'pending_amount_formatted',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 100,
  },
];

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'cs_operator',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Serie',
      attributeName: 'serie',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      transformValue: sentenceCase,
      attributeName: 'status',
    },
  },
  {
    type: 'rangeSlider',
    props: {
      title: 'Amount',
      attributeName: 'gross_amount',
      from: value => value,
    },
  },
  {
    type: 'rangeSlider',
    props: {
      title: 'Pending amount',
      attributeName: 'pending_amount',
      from: value => value,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Sent',
      transformValue: value => (isFalsyString(value) ? 'No' : 'Yes'),
      attributeName: 'sent',
    },
  },
];
