import { COLORS } from '@emobg/vue-base';

export const COMPANY_PAYMENT_METHOD_STATUS = {
  active: 'active',
  deactivated: 'deactivated',
};

export const COMPANY_PAYMENT_METHOD_STATUS_COLOR = {
  active: COLORS.success,
  deactivated: COLORS.warning,
};

export const COMPANY_PAYMENT_METHOD_STATUS_ACTIONS = {
  activate: 'Activate',
  deactivate: 'Deactivate',
};

export const COMPANY_PAYMENT_METHODS_OPTIONS = {
  bankTransfer: { label: 'Bank transfer', value: 'bank transfer' },
  sepa: { label: 'SEPA', value: 'SEPA' },
};
