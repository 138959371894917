<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import { contentCells, facets } from './config';
import CompanyPaymentMethodForm
  from './components/CompanyPaymentMethodForm';
import CompanyPaymentMethodStatusModal from './components/CompanyPaymentMethodStatusModal';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';
import {
  COMPANY_PAYMENT_METHOD_STATUS,
  COMPANY_PAYMENT_METHOD_STATUS_ACTIONS,
} from './const/companyPaymentMethods.const';

export default {
  name: 'CompanyPaymentMethodsView',
  components: {
    CompanyPaymentMethodStatusModal,
    CompanyPaymentMethodForm,
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
      isCompanyPaymentMethodsFormOpened: false,
      isCompanyPaymentMethodStatusFormOpened: false,
      formBinds: {
        action: null,
        companyPaymentMethod: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    contentCells() {
      return contentCells(this.operatorTimezone);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.facets = facets;
    this.filters = `company_fk:${this.company.id}`;
    const actionLabelStatus = companyPaymentMethod => {
      const action = companyPaymentMethod.status === COMPANY_PAYMENT_METHOD_STATUS.active
        ? COMPANY_PAYMENT_METHOD_STATUS_ACTIONS.deactivate
        : COMPANY_PAYMENT_METHOD_STATUS_ACTIONS.activate;
      return `${action} payment method`;
    };
    this.itemActions = [
      {
        label: actionLabelStatus,
        method: companyPaymentMethod => {
          const action = companyPaymentMethod.status === COMPANY_PAYMENT_METHOD_STATUS.active
            ? COMPANY_PAYMENT_METHOD_STATUS_ACTIONS.deactivate
            : COMPANY_PAYMENT_METHOD_STATUS_ACTIONS.activate;
          this.formBinds = {
            companyPaymentMethod,
            action,
          };
          this.isCompanyPaymentMethodStatusFormOpened = true;
        },
        isVisible: companyPaymentMethod => {
          const actions = [COMPANY_PAYMENT_METHOD_STATUS.active, COMPANY_PAYMENT_METHOD_STATUS.deactivated];
          return actions.includes(companyPaymentMethod.status);
        },
      },
    ];
  },
  methods: {
    get,
    getNoResultsLabel,
    closeModal() {
      this.isCompanyPaymentMethodsFormOpened = false;
      this.isCompanyPaymentMethodStatusFormOpened = false;
      this.formBinds = {
        companyPaymentMethod: null,
        action: null,
      };
    },
    onSuccessForm() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.companyPaymentMethods, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>
<template>
  <div
    data-test-id="company_payment_methods-view"
    class="CompanyPaymentMethodsView"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1>
        Payment methods
      </h1>
      <ui-button
        data-test-id="add_payment_method-button"
        @clickbutton="isCompanyPaymentMethodsFormOpened = true"
      >
        Add payment method
      </ui-button>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="companyPaymentMethods"
        :facets="facets"
        :filters="filters"
        :table-config="contentCells"
        :export-columns="contentCells"
        :index="ALGOLIA_INDEXES.companyPaymentMethods"
        :item-actions="itemActions"
        :empty-message="getNoResultsLabel('payment method')"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <CompanyPaymentMethodForm
      v-if="isCompanyPaymentMethodsFormOpened"
      key="paymentMethodForm"
      :company-id="get(company, 'id')"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
    <CompanyPaymentMethodStatusModal
      v-if="isCompanyPaymentMethodStatusFormOpened"
      v-bind="formBinds"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
  </div>
</template>
