import { isFalsyString, sentenceCase } from '@emobg/web-utils';
export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Default payment method',
      attributeName: 'default',
      transformValue: value => (isFalsyString(value) ? 'No' : 'Yes'),
    },
  },
];
