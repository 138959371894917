var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyPaymentsView",
      attrs: { "data-test-id": "company_payments-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Payments ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          index: _vm.ALGOLIA_INDEXES.paymentTransactions,
          "table-config": _vm.contentCells,
          filters: _vm.paymentsFilter,
          facets: _vm.facets,
          "empty-message": "No payments to show",
          "data-test-id": "list",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }