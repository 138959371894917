<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel } from '@/utils';
import { contentCells, facets } from './config/contentCells';

export default {
  name: 'CompanyInvoicesView',
  components: {
    MuiAlgoliaList,
  },
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.facets = facets;
    this.filter = `company_uuid:${this.companyUuid}`;
  },
  methods: { getNoResultsLabel },
};
</script>

<template>
  <div
    class="CompanyInvoicesView"
    data-test-id="company_invoices-view"
  >
    <h1 class="mb-2">
      Invoices
    </h1>
    <MuiAlgoliaList
      :index="ALGOLIA_INDEXES.invoices"
      :table-config="contentCells"
      :filters="filter"
      :facets="facets"
      empty-message="No invoices to show"
      data-test-id="table"
    />
  </div>
</template>
