import crmRoutes from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';

export const INVOICING_TABS = [
  {
    label: {
      title: 'Invoices',
    },
    url: crmRoutes.companies.detail.invoicing.invoices,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: 'Payments',
    },
    url: crmRoutes.companies.detail.invoicing.payments,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
  {
    label: {
      title: 'Payment methods',
    },
    url: crmRoutes.companies.detail.invoicing.paymentMethods,
    permissions: [CRM_PERMISSIONS.viewCrmCompanies],
  },
];
