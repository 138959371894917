import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const paymentStatusColors = {
  success: COLORS.success,
  pending: COLORS.warning,
  failed: COLORS.danger,
};

export const paymentProviderStatusColors = {
  authorised: COLORS.success,
  refused: COLORS.danger,
  error: COLORS.moderate,
  cancelled: GRAYSCALE.ground,
  received: COLORS.warning,
  pending: COLORS.warning,
  success: COLORS.success,
  identify: COLORS.warning,
  redirect: COLORS.warning,
  challenge: COLORS.warning,
};
